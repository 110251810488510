import { Badge } from "@capawesome/capacitor-badge"
import { useQuery } from "@tanstack/react-query"
import { useRouter } from "next/router"
import { useCallback, useEffect } from "react"

import { NotificationInstanceCount } from "../../../sdk"
import useUser from "../../hooks/User/useUser"
import { retrieveNotificationUnreadCount } from "../../requests/notifications"
import { QueryError } from "../../types/ReactQuery"
import { UNAUTHENTICATED_USER_MESSAGE, handleUnauthenticatedUser } from "./AuthCheck"

export default function NotificationBadgeProvider() {
    const { user, clearAuth } = useUser()
    const router = useRouter()

    // Pull the current unread notification count
    const {
        data: countResult,
        error: countError,
        isLoading: isCountLoading,
        isError: isCountError,
    } = useQuery<NotificationInstanceCount, QueryError, NotificationInstanceCount, string[]>({
        queryKey: ["notifications_unread_count", user?.token],
        queryFn: () => retrieveNotificationUnreadCount(user?.token),
        enabled: !!user,
        staleTime: 600000,
    })

    if (isCountError && countError?.message === UNAUTHENTICATED_USER_MESSAGE) {
        handleUnauthenticatedUser(router, clearAuth)
    }

    const setBadgeCount = useCallback(async (count: number) => {
        const supported = await Badge.isSupported()

        if (supported) {
            const permissionStatus = await Badge.checkPermissions()

            if (permissionStatus.display !== "granted") {
                console.error("User denied badge permissions.")
            } else {
                try {
                    await Badge.set({ count })
                } catch (error) {
                    console.error("Failed to set badge count.", error)
                }
            }
        }
    }, [])

    useEffect(() => {
        if (user && !isCountLoading && !isCountError && countResult) {
            setBadgeCount(countResult.count)
        }
    }, [user, isCountLoading, isCountError, countResult, setBadgeCount])

    return null
}
